import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useToken } from '../contexts/TokenContext';
import { useUser } from '../contexts/UserContext'; // Importa o hook useUser

const Login = () => {
    const [login, setLogin] = useState('');
    const [senha, setSenha] = useState('');
    const [message, setMessage] = useState('');
    const { setToken } = useToken();
    const { setUser } = useUser(); // Usa o hook useUser para atualizar o usuário
    const navigate = useNavigate();

    const handleRefresh = async () => {
        window.location.reload();
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        try {
            // Acessa a URL da API a partir do arquivo config.js
            const baseURL = window.APP_CONFIG?.Url_Api || 'http://default-url.com';            
            const userSpecificUrl = `${baseURL}/login`;
            console.log(`URL: ${userSpecificUrl}`)
            const response = await axios.post(userSpecificUrl, {             
                login,
                senha
            });

            setToken(response.data.token);
            setUser(response.data.user.nome); // Atualiza o login do usuário no contexto
            localStorage.setItem('userLogin', response.data.user.nome);
            setMessage('Login successful!');

            // Redirecionar para a página de pedidos
            navigate('/pedido');

            //Adicionei refresh pq o menu bugava
            handleRefresh();
        } catch (error) {
            setMessage('Invalid username or password');
        }
    };

    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            backgroundColor: '#f0f2f5',
        }}>
            <div style={{
                width: '100%',
                maxWidth: '400px',
                padding: '20px',
                backgroundColor: 'white',
                borderRadius: '8px',
                boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
            }}>
                <div className="card card-outline card-primary">
                    <div className="card-header text-center">
                        <a href="../../index2.html" className="h1"><b>Go</b>Delivery</a>
                    </div>
                    <div className="card-body">
                        <p className="login-box-msg">Faça login para iniciar a sessão</p>
                        <form onSubmit={onSubmit}>
                            <div className="input-group mb-3">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Login"
                                    value={login}
                                    onChange={(e) => setLogin(e.target.value)}
                                    required
                                />
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                        <span className="fas fa-user" />
                                    </div>
                                </div>
                            </div>
                            <div className="input-group mb-3">
                                <input
                                    type="password"
                                    className="form-control"
                                    placeholder="Senha"
                                    value={senha}
                                    onChange={(e) => setSenha(e.target.value)}
                                    required
                                />
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                        <span className="fas fa-lock" />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-8">
                                    <div className="icheck-primary">
                                        <input type="checkbox" id="remember" />
                                        <label htmlFor="remember">
                                            Lembre-me
                                        </label>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <button type="submit" className="btn btn-primary btn-block">Acessar</button>
                                </div>
                            </div>
                        </form>
                        {message && <p className="mt-3 text-center text-danger">{message}</p>}
                        <p className="mb-1">
                            <a href="forgot-password.html">Esqueci minha senha</a>
                        </p>
                        <p className="mb-0">
                            <a href="register.html" className="text-center">Cadastrar novo usuário</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
