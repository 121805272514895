import React from "react";

const InputMultiline = ({ label, name, placeholder, rows, register, required, msgErro }) => (
  <div className="form-group">
    <label>{label}</label>
    <textarea 
      className={!msgErro ? "form-control" : "form-control is-invalid"}
      aria-invalid={!msgErro ? "false" : "true"}
      rows={rows}
      placeholder={placeholder} 
      {...register(name, { required })} 
    />
    {msgErro && <span className="error invalid-feedback">{msgErro}</span>}
  </div>
);

export default InputMultiline;
