// Modal.js
import React from 'react';
//import 'bootstrap/dist/css/bootstrap.min.css';

const ModalYesNo = ({ isOpen, onClose, onConfirm, children }) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className="modal fade show" style={{ display: 'block' }}>
      <div className="modal-dialog" style={{ maxWidth: '400px', margin: '10rem auto' }}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Confirmação</h5>
            <button type="button" className="btn-close" onClick={onClose}></button>
          </div>
          <div className="modal-body">
            {children}
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={onClose}>Não</button>
            <button type="button" className="btn btn-primary" onClick={onConfirm}>Sim</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalYesNo;
