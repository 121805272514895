import React from "react";

const Select = ({ children, label, name, options, valorPadrao, register, required, msgErro, onChange }) => (
    <div className="form-group">
        <label>{label}</label>
        <select
            className={!msgErro ? "form-control" : "form-control is-invalid"}
            aria-invalid={!msgErro ? "false" : "true"}
            {...register(name, { required })}
            onChange={onChange}
        >
            <option value="">Selecione...</option>
            {options.map((option) => (
                <option key={option.id} value={option.id} selected={valorPadrao === option.id}>
                    {option.descricao}
                </option>
            ))}
        </select>
        {msgErro && <span className="error invalid-feedback">{msgErro}</span>}
        {children}
    </div>
);

export default Select;
