import axios from 'axios';


// Cria uma instância do Axios
const api = axios.create({
  // Acessa a URL da API a partir do arquivo config.js
  baseURL: window.APP_CONFIG?.Url_Api || 'http://default-url.com', // Adicione uma URL padrão caso a configuração falhe
  // Outros parâmetros padrão, se necessário (ex.: headers)
});

// Função genérica para requisições GET
export const fetchData = async (endpoint, params = {}) => {
  try {
    //console.log(params);
    const response = await api.get(endpoint, { params });
    return response.data;
  } catch (error) {
    console.error(`Erro ao obter dados de ${endpoint}:`, error);
    throw error;
  }
};

// Função genérica para requisições POST
export const postData = async (endpoint, data) => {
  try {    
    const response = await api.post(endpoint, data);
    return response.data;
  } catch (error) {
    console.error(`Erro ao enviar dados para ${endpoint}:`, error);
    throw error;
  }
};

// Função genérica para requisições PUT
export const putData = async (endpoint, data) => {
  try {       
    const response = await api.put(endpoint, data);
    return response.data;
  } catch (error) {
    console.error(`Erro ao enviar dados para ${endpoint}:`, error);
    throw error;
  }
};


// Função genérica para requisições DELETE
export const deleteData = async (endpoint,  params = {}) => {
  try {    
    const response = await api.delete(endpoint, { params });
    return response.data;
  } catch (error) {
    console.error(`Erro ao requisitar o delete ${endpoint}:`, error);
    throw error;
  }
};



// Interceptores de requisição
api.interceptors.request.use(config => {
  // Adicione manipulação de token ou qualquer outra configuração de cabeçalho aqui
  const token = localStorage.getItem('token');
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
}, error => {
  return Promise.reject(error);
});

// Interceptores de resposta
api.interceptors.response.use(response => {
  return response;
}, error => {
  if (error.response.status === 401 || error.response.status === 403) {
    // Remove o token do localStorage
    localStorage.removeItem('token');

    // Opcional: Redirecionar para a página de login
    window.location.href = '/login';
  }
  return Promise.reject(error);
});


// Exporta a instância do axios se precisar fazer outras requisições diretas
export default api;
