// src/components/form/input/InputHora.js

import React from 'react';
import InputMask from 'react-input-mask';
//import './InputHora.css'; // Arquivo CSS opcional para estilização

const InputHora = ({ label, name, placeholder, register, required, msgErro, disabled, style, onChange }) => (
  <div className="form-group">
    {label && <label>{label}</label>}
    <InputMask
      mask="99:99"
      className={!msgErro ? "form-control" : "form-control is-invalid"}
      aria-invalid={!msgErro ? "false" : "true"}
      placeholder={placeholder}
      {...register(name, { required })}
      disabled={disabled}
      style={style}
      onChange={onChange}
    />
    {msgErro && <span className="error invalid-feedback">{msgErro}</span>}
  </div>
);

export default InputHora;
