import React from "react";
import { Controller } from "react-hook-form";
import { NumericFormat } from "react-number-format";

const InputMoney = ({ label, name, placeholder, control, required, error }) => {
  return (
    <div className="form-group">
      <label>{label}</label>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <NumericFormat
            {...field}
            thousandSeparator="."
            decimalSeparator=","
            prefix="R$ "
            allowNegative={false}
            placeholder={placeholder}
            type="text"
            className={error ? "form-control is-invalid" : "form-control"}
          />
        )}
        rules={{ required: required }}
      />
      {error && <div className="invalid-feedback">{error.message}</div>}
    </div>
  );
};

export default InputMoney;
