// ModalDetalhePedido.js
import React, { useEffect, useState } from "react";
import { formatCurrency } from "../../utils/numberUtils";
import "./ModalDetalhePedido.css"; // Mantenha a importação do CSS
import { putData } from "../../services/Api";
import { BsDot, BsWhatsapp } from "react-icons/bs";
import CadPedido from "./CadPedido";
import { handleEnviarPedido } from "../../components/handleEnviarPedido";

const ModalDetalhePedido = ({ isOpen, onClose, pedido }) => {
  const [alterarPedido, setAlterarPedido] = useState(false);
  const [updateId, setUpdateId] = useState(false);

  if (!pedido) {
    pedido = {
      id: 0,
      nomeCliente: "",
      statusPedido: { id: 3, descricao: "" },
      tipoEntrega: { id: 1, descricao: "", taxaEntrega: 0 },
      tipoPagamento: { id: 1, descricao: "" },
      enderecoCliente: "",
      pedidoItens: [],
    };
  }

  useEffect(() => {
    const fetchTipoEntrega = async () => {
      if (pedido.id === 0) {
        setAlterarPedido(true);        
      } else {
        setUpdateId(pedido.id);
        setAlterarPedido(false);        
      }
    };

    fetchTipoEntrega();
  }, [isOpen]);

  if (!isOpen || !pedido) {
    return null;
  }

  const valorEntrega = pedido.taxaEntrega;
  const valorTotal = pedido.valorTotal;

  // Função para determinar o texto do botão com base no status do pedido
  const getButtonLabel = (status) => {
    switch (status) {
      case "Em análise":
        return "Aceitar Pedido";
      case "Em Produção":
        return "Concluir Preparo";
      case "Aguardando Retirada/Entrega":
        return "Concluir Pedido";
      default:
        return "Fechar";
    }
  };

  const handleAlteraStatus = async () => {
    try {
      const pedidoId = pedido.id;
      const statusPedidoId = pedido.statusPedido.id + 1;

      const payload = {
        pedidoId: pedidoId,
        statusPedidoId: statusPedidoId,
      };

      const response = await putData("/Pedido/AlteraStatusPedido", payload);
      console.log("Status alterado com sucesso:", response);
      onClose();
    } catch (error) {
      console.error("Erro ao alterar status do pedido:", error);
    }
  };

 
  


  const handleFechar = async () => {
    try {
      onClose();
    } catch (error) {
      console.error("Erro ao alterar status do pedido:", error);
    }
  };

  const handleAlterarPedido = async () => {
    
    setAlterarPedido(true);
  };

  const closeCadastro = () => {
    setAlterarPedido(false);
    onClose();
    // Recarregar a lista de variações após o cadastro (se necessário)
  };

  const getHoraPrevisao = () => {
    let hora ="";
    // Verifica a quantidade de caracteres da string dataHoraPrevisao
    const quantidadeCaracteres = pedido.dataHoraPrevisao ? pedido.dataHoraPrevisao.length : 0;          
    if (quantidadeCaracteres>5)      
    {
      hora = new Date(pedido.dataHoraPrevisao).toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      })    
    } else {
      hora = pedido.dataHoraPrevisao;
    }


    return hora;
  }

  return (
    <div className="modal fade show" style={{ display: "block" }}>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Detalhes do Pedido Nº {updateId}</h5>
            <button type="button" className="close" onClick={onClose}>
              <span>&times;</span>
            </button>
          </div>

          {alterarPedido ? (
            <CadPedido pedidoData={pedido} onClose={closeCadastro} setIdNovoPedido={setUpdateId}/>
          ) : (
            <>
              <div className="modal-body">
                <div className="flex-container">
                  <p>
                    <strong>Cliente:</strong>{" "}
                    {pedido.cliente ? pedido.cliente.nome : pedido.nomeCliente}
                  </p>
                  <p>
                    <strong>Hora:</strong>{" "}
                    {new Date(pedido.dataHoraPedido).toLocaleTimeString([], {
                      hour: "2-digit",
                      minute: "2-digit",
                    })}
                  </p>
                </div>
                <div className="flex-container">
                <p>
                  <strong>Forma de Pagamento:</strong>{" "}
                  {pedido.tipoPagamento
                    ? pedido.tipoPagamento.descricao
                    : "N/A"}
                </p>
                <p>
                    <strong>Previsão:</strong>{" "}
                    {getHoraPrevisao()}                    
                  </p>
                </div>
                <div className="flex-container">
                <p>
                  <strong>Modo de Entrega:</strong>{" "}
                  {pedido.tipoEntrega ? pedido.tipoEntrega.descricao : "N/A"}
                </p>
                {pedido.cliente && (
                  <p>
                  <strong>Telefone:</strong>{" "}
                  {pedido.cliente.telefone}   
                </p>
                )}
                    
                </div>
                {pedido.tipoEntrega.descricao === "Delivery" && (
                  <p>
                    {pedido.cliente ? (
                      <>
                        <strong>Endereço: </strong> Logradouro: {pedido.endereco.logradouro} Bairro: {pedido.endereco.bairro} Complemento: {pedido.endereco.complemento}
                      </>
                    ) : (
                      <>
                        <strong>Endereço: </strong> 
                        {pedido.enderecoCliente}
                      </>
                    )}
                  </p>
                )}

                <h5>Produtos:</h5>
                <table className="table">
                  <thead>
                    <tr>
                      <th>Produto</th>
                      <th className="direita">Qtde</th>
                      <th className="direita">Valor</th>
                    </tr>
                  </thead>
                  <tbody>
                    {pedido.pedidoItens.map((item) => (
                      <React.Fragment key={item.id}>
                        <tr>
                          <td>
                            {item.produto.descricao}{" "}
                            {item.produto.variacao.descricao}
                          </td>
                          <td className="direita">{item.qtde}</td>
                          <td className="direita">
                            {formatCurrency(item.valor)}
                          </td>
                        </tr>
                        {item.observacao && (
                          <tr className="observacao-row">
                            <td colSpan="3" className="observacao">
                              Obs.: {item.observacao}
                            </td>
                          </tr>
                        )}
                        {item.pedidoItemSabores &&
                          item.pedidoItemSabores.length > 0 && (
                            <tr className="observacao-row">
                              <td colSpan="3" className="observacao">
                                Sabores:
                                {item.pedidoItemSabores.map((sabor) => (
                                  <div key={sabor.id} className="saborItem">
                                    <BsDot /> {sabor.sabor.codSabor} -{" "}
                                    {sabor.sabor.descricao}
                                  </div>
                                ))}
                              </td>
                            </tr>
                          )}
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
                <div className="valores-direita">
                  <p>
                    <strong>Valor Entrega: </strong>
                    {formatCurrency(valorEntrega)}
                  </p>
                  <p>
                    <strong>Valor Total: </strong>
                    {formatCurrency(valorTotal)}
                  </p>
                </div>
              </div>

              <div className="modal-footer flex-footer">
                <button
                  className="btn btn-secondary"
                  onClick={handleAlterarPedido}
                >
                  Alterar Pedido
                </button>

                <button className="btn btn-secondary">
                  <a
                    href={handleEnviarPedido(pedido)}
                    style={{ textDecoration: 'none', color: 'inherit' }}
                  >
                    Enviar Pedido <BsWhatsapp />
                  </a>
                </button>

                <button
                  type="button"
                  className="btn btn-success"
                  onClick={handleAlteraStatus}
                >
                  {getButtonLabel(pedido.statusPedido.descricao)}
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ModalDetalhePedido;
