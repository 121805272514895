import React, { useEffect, useState } from 'react';
import FormCad from '../../../components/FormCad';
import Input from '../../../components/form/input/Input';
import InputMultiline from '../../../components/form/input/InputMultiline';
import { useForm } from 'react-hook-form';
import { fetchData, postData, putData } from '../../../services/Api';
import Select from '../../../components/form/input/Select';

function CadSabor({ saborData, onClose }) {
  const [grupos, setGrupos] = useState([]);

    useEffect(() => {
        const fetchDataFromAPI = async () => {
            try {
                const result = await fetchData('/Grupo');
                setGrupos(result);
            } catch (error) {
                console.error("Erro ao buscar grupos:", error);
            }
        };
        fetchDataFromAPI();
    }, []);


  // Define valores padrão se saborData não estiver presente
  if (!saborData) {
    saborData = {
        id: 0,
        tipoSabor: 1,
        codSabor: "",
        descricao: "",
        ingredientes: "",
        grupo: { id: 0, descricao: "" }
    };
  }

  const { register, formState: { errors }, handleSubmit, setValue } = useForm({
    defaultValues:saborData,
  });

  const onSubmit = async (data) => {
    try {
      // Transformar tipoSabor para número inteiro
      data.tipoSabor = parseInt(data.tipoSabor, 10);  
      
      if (data.id !== 0) {
        const response = await putData('/Sabor', data);
        console.log("Resposta da API: ", response);
      } else {
        const response = await postData('/Sabor', data);
        console.log("Resposta da API: ", response);
      }
      onClose(); // Fechar a tela de cadastro após o envio bem-sucedido
    } catch (error) {
      //console.error("Erro ao gravar dados: ", error);
    }
  };

  return (
    <FormCad
      cabecalho="Cadastro de Sabores"
      titulo={saborData.id !== 0 ? "Alterar Sabor" : "Cadastrar Novo Sabor"}
      nameBtnGravar="Gravar"
      cancelarClick={onClose}
      gravarClick={handleSubmit(onSubmit)}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="card-body">

        <div>
            <label htmlFor="tipoSabor">Tipo do Sabor:</label><br />
            <div className="form-check form-check-inline">
                <input
                    className="form-check-input"
                    type="radio"
                    value={1}
                    {...register("tipoSabor")}
                    defaultChecked={saborData.tipoSabor === 1}
                />
                <label className="form-check-label">Salgado</label>
            </div>
            <div className="form-check form-check-inline">
                <input
                    className="form-check-input"
                    type="radio"
                    value={2}
                    {...register("tipoSabor")}
                    defaultChecked={saborData.tipoSabor === 2}
                />
                <label className="form-check-label">Doce</label>
            </div>
        </div>
        <Select
                        label="Grupo"
                        name="grupo.id"
                        options={grupos}
                        valorPadrao={saborData.grupo.id}
                        msgErro={errors.grupo?.id?.message}
                        register={register}
                        required={{ value: true, message: "Grupo é obrigatório" }} // Definir a mensagem da validação no formulário
          />
          <Input
            label="Cód Sabor"
            name="codSabor"
            placeholder="Informe o Código do Sabor"
            msgErro={errors.codSabor?.message}
            register={register}
            required="O código do sabor é obrigatório"
          />
          <Input
            label="Descrição"
            name="descricao"
            placeholder="Informe a Descrição do Sabor"
            msgErro={errors.descricao?.message}
            register={register}
            required="Descrição do Sabor é obrigatória"
          />
          <InputMultiline
            label="Ingredientes"
            name="ingredientes"
            rows={3}
            placeholder="Informe os ingredientes do sabor"
            msgErro={errors.ingredientes?.message}
            register={register}
            required="É obrigatório informar os ingredientes"
          />
        </div>
      </form>
    </FormCad>
  );
}

export default CadSabor;
