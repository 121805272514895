import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Importar o useNavigate
import ModalYesNo from '../../../components/form/modal/ModalYesNo';
import FormVazio from '../../../components/form/FormVazio';
import { fetchData, deleteData } from '../../../services/Api';
import CadVariacao from './CadVariacao';

function ViewVariacao() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isCadastroOpen, setIsCadastroOpen] = useState(false);
    const [variacoes, setVariacoes] = useState([]);
    const [variacaoSel, setVariacaoSel] = useState(null);

    const navigate = useNavigate(); // Definir o hook useNavigate
    const handleGoBack = () => {
        navigate(-1); // Navegar para a tela anterior    
    };

    useEffect(() => {
        const fetchDataFromAPI = async () => {
            try {
                const result = await fetchData('/Variacao');
                setVariacoes(result);
            } catch (error) {
                console.error("Erro ao buscar variações:", error);
            }
        };
        fetchDataFromAPI();
    }, []);

    const closeModal = () => {
        setIsModalOpen(false);
        setVariacaoSel(null); // Limpa o ID do grupo a ser excluído ao fechar a modal
    };

    const excluirRegistro = (variacao) => {
        setVariacaoSel(variacao); // Armazenar o ID do grupo a ser excluído        
        setIsModalOpen(true);
    };

    const handleModalConfirm = async () => {
        try {
            const idVariacao = variacaoSel.id
            console.log(idVariacao);
            await deleteData('/Variacao', { variacaoId: idVariacao }); // Exclusão da variacao no servidor

            setVariacoes(variacoes.filter(variacao => variacao.id !== idVariacao)); // Atualiza a lista de variacoes
            console.log('Variacao ' + idVariacao + ' deletada com sucesso');
        } catch (error) {
            console.error('Erro ao deletar o variação:', error);
        } finally {
            closeModal(); // Fecha a modal e limpa o ID da variacao a ser excluída
        }
    };

    const openCadastroInc = () => {
        setVariacaoSel(null);
        setIsCadastroOpen(true);
    };

    const openCadastroAlt = (grupo) => {
        setVariacaoSel(grupo);
        setIsCadastroOpen(true);
    };

    const closeCadastro = () => {
        setIsCadastroOpen(false);
        // Recarregar a lista de variações após o cadastro (se necessário)
        const fetchDataFromAPI = async () => {
            try {
                const result = await fetchData('/Variacao');
                setVariacoes(result);
            } catch (error) {
                console.error("Erro ao buscar grupos:", error);
            }
        };
        fetchDataFromAPI();
    };

    return (
        <>
            {isCadastroOpen ? (
                <CadVariacao variacaoData={variacaoSel} onClose={closeCadastro} />
            ) : (
                <FormVazio cabecalho="Cadastro de Variações" titulo="Cadastrar Nova Variação">
                    <section className="content">
                        <div className="card-header">
                            <button className="btn btn-secondary float" onClick={handleGoBack}>Voltar</button>
                            <button className="btn btn-success float-right" onClick={openCadastroInc}>Incluir Variação</button>
                        </div>

                        <br />

                        {variacoes && (
                            <div className="card-body p-0">
                                <table className="table table-striped projects">
                                    <thead>
                                        <tr>
                                            <th style={{ width: '10%' }}>Código</th>
                                            <th style={{ width: '30%' }}>Descrição</th>
                                            <th style={{ width: '30%' }}>Grupo</th>
                                            <th style={{ width: '30%' }}></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {variacoes.map((variacao) => (
                                            <tr key={variacao.id}>
                                                <td>
                                                    <a><b>{variacao.id}</b></a>
                                                </td>
                                                <td>
                                                    <a>{variacao.descricao}</a>
                                                </td>
                                                <td>
                                                    <a>{variacao.grupo.descricao}</a>
                                                </td>
                                                <td className="project-actions text-right">
                                                    <a className="btn btn-info btn-sm" onClick={() => openCadastroAlt(variacao)}>
                                                        <i className="fas fa-pencil-alt"></i>
                                                        Editar
                                                    </a>
                                                    <a className="btn btn-danger btn-sm" onClick={() => excluirRegistro(variacao)}>
                                                        <i className="fas fa-trash"></i>
                                                        Excluir
                                                    </a>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </section>

                    <ModalYesNo isOpen={isModalOpen} onClose={closeModal} onConfirm={handleModalConfirm}>
                        <span>Deseja realmente excluir o grupo selecionado?</span>
                    </ModalYesNo>
                </FormVazio>
            )}
        </>
    );
}

export default ViewVariacao;
