import React from "react";
//import { useNavigate } from "react-router-dom";


const FormVazio = ({ children, cabecalho, nomeLink }) => {

    /*
    const navigate = useNavigate();
    const handleHome = (e) => {
        e.preventDefault();        
        navigate('/');
    };
    */

    return (        

        <div>
            {/* Content Wrapper. Contains page content */}

            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>{cabecalho}</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><a href="#">Home</a></li>                                    
                                    <li className="breadcrumb-item active">{nomeLink}</li>
                                </ol>
                            </div>
                        </div>
                    </div>{/* /.container-fluid */}
                </section>

                {children}

            </div>
            {/* /.content-wrapper */}

        </div>

    );
}

export default FormVazio;
