import React, { useEffect, useState } from 'react';
import FormCad from '../../../components/FormCad';
import Input from '../../../components/form/input/Input';
import Select from '../../../components/form/input/Select';
import { useForm } from 'react-hook-form';
import { fetchData, postData, putData } from '../../../services/Api';

function CadVariacao({ variacaoData, onClose }) {
    const [grupos, setGrupos] = useState([]);

    useEffect(() => {
        const fetchDataFromAPI = async () => {
            try {
                const result = await fetchData('/Grupo');
                setGrupos(result);
            } catch (error) {
                console.error("Erro ao buscar grupos:", error);
            }
        };
        fetchDataFromAPI();
    }, []);

    if (!variacaoData) {
        variacaoData = {
            id: 0,
            descricao: "",
            grupo: { id: 0, descricao: "" }
        };
    }

    const { register, formState: { errors }, handleSubmit, setValue } = useForm({
        defaultValues: variacaoData,
    });

    const onSubmit = async (data) => {
        try {
            if (variacaoData.id !== 0) {
                const response = await putData('/Variacao', data);
                console.log("Resposta da API: ", response);
            } else {
                const response = await postData('/Variacao', data);
                console.log("Resposta da API: ", response);
            }
            onClose(); // Fechar a tela de cadastro após o envio bem-sucedido
        } catch (error) {
            console.error("Erro ao gravar dados: ", error);
            // Você pode exibir uma mensagem de erro aqui, se necessário
        }
    };

    return (
        <FormCad
            cabecalho="Cadastro de Variações"
            titulo={variacaoData.id === 0 ? "Cadastrar Nova Variação" : "Alterar Variação"}
            nameBtnGravar="Gravar"
            cancelarClick={onClose}
            gravarClick={handleSubmit(onSubmit)}
        >
            <form>
                <div className="card-body">
                    <Select
                        label="Grupo"
                        name="grupo.id"
                        options={grupos}
                        valorPadrao={variacaoData.grupo.id}
                        msgErro={errors.grupo?.id?.message}
                        register={register}
                        required={{ value: true, message: "Grupo é obrigatório" }} // Definir a mensagem da validação no formulário
                    />
                    <Input
                        label="Descrição"
                        name="descricao"
                        placeholder="Informe a Descrição da Variação"
                        msgErro={errors.descricao?.message}
                        register={register}
                        required={{ value: true, message: "Descrição da variação é obrigatória" }} // Definir a mensagem da validação no formulário
                    />
                </div>
            </form>
        </FormCad>
    );
}

export default CadVariacao;
