import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Importar o useNavigate
import ModalYesNo from '../../../components/form/modal/ModalYesNo';
import FormVazio from '../../../components/form/FormVazio';
import CadUsuario from './CadUsuario';
import { fetchData, deleteData } from '../../../services/Api';

function ViewUsuario() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isCadastroOpen, setIsCadastroOpen] = useState(false);
    const [usuarios, setUsuarios] = useState([]);
    const [usuarioSel, setUsuarioSel] = useState(null);

    const navigate = useNavigate(); // Definir o hook useNavigate
    const handleGoBack = () => {
        navigate(-1); // Navegar para a tela anterior    
    };

    useEffect(() => {
        const fetchDataFromAPI = async () => {
            try {
                const result = await fetchData('/Usuario');
                setUsuarios(result);
            } catch (error) {
                console.error("Erro ao buscar usuários:", error);
            }
        };
        fetchDataFromAPI();
    }, []);

    const closeModal = () => {
        setIsModalOpen(false);
        setUsuarioSel(null); // Limpa o ID do usuário a ser excluído ao fechar a modal
    };

    const excluirRegistro = (usuario) => {
        setUsuarioSel(usuario); // Armazenar o ID do usuário a ser excluído        
        setIsModalOpen(true);
    };

    const handleModalConfirm = async () => {
        try {
            const idUsuario = usuarioSel.id;
            console.log(idUsuario);
            await deleteData('/Usuario', { usuarioId: idUsuario }); // Exclusão do usuário no servidor

            setUsuarios(usuarios.filter(usuario => usuario.id !== idUsuario)); // Atualiza a lista de usuários
            console.log('Usuário ' + idUsuario + ' deletado com sucesso');
        } catch (error) {
            console.error('Erro ao deletar o usuário:', error);
        } finally {
            closeModal(); // Fecha a modal e limpa o ID do usuário a ser excluído
        }
    };

    const openCadastroInc = () => {
        setUsuarioSel(null);
        setIsCadastroOpen(true);
    };

    const openCadastroAlt = (usuario) => {
        setUsuarioSel(usuario);
        setIsCadastroOpen(true);
    };

    const closeCadastro = () => {
        setIsCadastroOpen(false);
        // Recarregar a lista de usuários após o cadastro (se necessário)
        const fetchDataFromAPI = async () => {
            try {
                const result = await fetchData('/Usuario');
                setUsuarios(result);
            } catch (error) {
                console.error("Erro ao buscar usuários:", error);
            }
        };
        fetchDataFromAPI();
    };

    return (
        <>
            {isCadastroOpen ? (
                <CadUsuario usuarioData={usuarioSel} onClose={closeCadastro} />
            ) : (
                <FormVazio cabecalho="Cadastro de Usuários">
                    <section className="content">
                        <div className="card-header">
                            <button className="btn btn-secondary float" onClick={handleGoBack}>Voltar</button>
                            <button className="btn btn-success float-right" onClick={openCadastroInc}>Incluir Usuário</button>
                        </div>

                        <br />
                        
                        {usuarios && (
                            <div className="card-body p-0">
                                <table className="table table-striped projects">
                                    <thead>
                                        <tr>
                                            <th style={{ width: '10%' }}>Código</th>
                                            <th style={{ width: '20%' }}>Nome</th>
                                            <th style={{ width: '20%' }}>Login</th>
                                            <th style={{ width: '10%' }}></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {usuarios.map((usuario) => (
                                            <tr key={usuario.id}>
                                                <td>
                                                    <a><b>{usuario.id}</b></a>
                                                </td>
                                                <td>
                                                    <a>{usuario.nome}</a>
                                                </td>
                                                <td>
                                                    <a>{usuario.login}</a>
                                                </td>
                                                <td className="project-actions text-right">
                                                    <a className="btn btn-info btn-sm" onClick={() => openCadastroAlt(usuario)}>
                                                        <i className="fas fa-pencil-alt"></i>
                                                        Editar
                                                    </a>
                                                    <a className="btn btn-danger btn-sm" onClick={() => excluirRegistro(usuario)}>
                                                        <i className="fas fa-trash"></i>
                                                        Excluir
                                                    </a>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </section>

                    <ModalYesNo isOpen={isModalOpen} onClose={closeModal} onConfirm={handleModalConfirm}>
                        <span>Deseja realmente excluir o usuário selecionado?</span>
                    </ModalYesNo>
                </FormVazio>
            )}
        </>
    );
}

export default ViewUsuario;
