import React, { useEffect, useState } from 'react';
import { fetchData, postData, putData } from '../../services/Api';
import { useForm } from 'react-hook-form';
import SelectSearch from '../../components/form/input/SelectSearch';
import Input from '../../components/form/input/Input';
import InputMultiline from '../../components/form/input/InputMultiline';
import { formatCurrency, parseCurrency } from '../../utils/numberUtils';

const ModalItemPedido = ({ isOpen, onClose, itemPedidoData, pedidoId }) => {
  if (!itemPedidoData) {
    itemPedidoData = {
      id: 0,
      pedidoId: pedidoId,
      produto: { id: 0, descricao: "" },
      pedidoItemSabores:[],
      qtde: 0,
      valor: 0,
      observacao: "",
    };
  }

  const { register, formState: { errors }, 
  handleSubmit, //Submit do hook
  reset, //Ajusta os campos de acordo com o selecionado
  setValue, //Seta valores no hook
  getValues //Pega valores do hook
} = useForm({
    defaultValues: itemPedidoData,
  });

  const [pedidoItemSabores, setPedidoItemSabores] = useState([]);
  const [produtosCombo, setProdutosCombo] = useState([]);
  
  const [qtdeSabor, setQtdeSabor] = useState(0);

  const [saboresCombo, setSaboresCombo] = useState();
  const [saborSel, setSaborSel] = useState();

  const [valorUn, setValorUn] = useState(0);

  useEffect(() => {
    const fetchProdutos = async () => {
      try {
        const result = await fetchData('/Produto/GetAllDescVal');
        setProdutosCombo(result);
      } catch (error) {
        console.log("Erro ao buscar produtos: " + error);
      }
    };
    fetchProdutos();
  }, []);

  useEffect(() => {
    reset(itemPedidoData);
    if (itemPedidoData.id !== 0) {
      setValue('valor', formatCurrency(itemPedidoData.valor));
      if (itemPedidoData.produto.qtdeSabor > 0) {
        setQtdeSabor(itemPedidoData.produto.qtdeSabor);
        const fetchSabores = async () => {
          try {
            const result = await fetchData('/Sabor/SaboresByProduto', { produtoId: itemPedidoData.produto.id });
            
            setSaboresCombo(result);
          } catch (error) {
            console.log("Erro ao buscar produtos: " + error);
          }
        };
        fetchSabores();
      }
    }
  }, [isOpen]);

  const handleProdutoChange = async (selectedOption) => {
    if (selectedOption) {
      const produtoSelecionado = produtosCombo.find(produto => produto.id === selectedOption.value);
      if (produtoSelecionado) {
        setValue('valor', formatCurrency(produtoSelecionado.valor));
        setValue('qtde', 1);
        setValue('produto.id', produtoSelecionado.id);

        setValorUn(produtoSelecionado.valor);


        if (produtoSelecionado.qtdeSabor > 0) {
          setQtdeSabor(produtoSelecionado.qtdeSabor);
          try {
            const result = await fetchData('/Sabor/SaboresByGrupo', { grupoId: produtoSelecionado.grupo.id });
            setSaboresCombo(result);
          } catch (error) {
            console.log("Erro ao buscar produtos: " + error);
          }
        } else {
          setSaboresCombo(null);
          setQtdeSabor(0);
        }
      }
    }
  };

  const qtdeChange = async (qtdeInput) => {
    
    if (qtdeInput.target.value!=="") {

      const valorTotal = qtdeInput.target.value*valorUn;

      setValue('valor', formatCurrency(valorTotal));
      //reset()

    }
  };

  const onSubmit = async (data) => {
    try {
      data.pedido = { id: pedidoId };

      
      data.valor = parseCurrency(data.valor);
      if (pedidoItemSabores)
      {
        data.pedidoItemSabores=pedidoItemSabores;
      }

      console.log('/PedidoItem', data);
      
      if (itemPedidoData.id !== 0) {
        await putData('/PedidoItem', data);
      } else {              
        const response = await postData('/PedidoItem', data);
        itemPedidoData.id = response.id;
      }
      //setPedidoItemSabores(null);
      onClose();
    } catch (error) {
      console.error("Erro ao gravar dados: ", error);
    }
  };

  const insertSabor = () => {
    if (saborSel)
    {
      const selectedSabor = saboresCombo.find(sabor => sabor.id === saborSel);

      const novoSabor = {
        //pedidoItem: itemPedidoData.id,
        sabor: {
          id: selectedSabor.id,
          codSabor: selectedSabor.codSabor,
          descricao: selectedSabor.descricao
        },
        qtde: 1
      };    

      setPedidoItemSabores([...pedidoItemSabores, novoSabor]);
  }
  else{alert('Informe um Sabor')}
  };

  const handleSaborChange = async (selectedOption) => {

    if (selectedOption) {
      setSaborSel(selectedOption.value);
      
    }
  };



  const deleteSabor = (saborToDelete) => {
    // Filtra a lista de sabores, excluindo o sabor que corresponde ao que foi clicado
    const novaListaSabores = pedidoItemSabores.filter(
      (pedidoItemSabor) => pedidoItemSabor.sabor.id !== saborToDelete.sabor.id
    );
  
    // Atualiza o estado com a nova lista
    setPedidoItemSabores(novaListaSabores);
  };

  if (!isOpen) return null;

  return (
    <div className="modal fade show" style={{ display: "block" }}>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Cadastro de Itens do Pedido</h5>
            <button type="button" className="close" onClick={onClose}>
              <span>&times;</span>
            </button>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="modal-body">
              <div className="col-md-6">
                <SelectSearch
                  label="Produto"
                  name="produto.id"
                  options={produtosCombo}
                  valorPadrao={itemPedidoData.produto.id}
                  msgErro={errors.produto?.id?.message}
                  register={register}
                  required={{ value: true, message: "Produto é obrigatório" }}
                  onChange={handleProdutoChange}
                />
              </div>
              <div className="form-row">
                <div className="col-md-3">
                  <Input
                    label="Quantidade"
                    name="qtde"
                    placeholder="Informe a Quantidade"
                    msgErro={errors.qtde?.message}
                    register={register}                    
                    required="Quantidade é obrigatória"
                    onChange={qtdeChange}
                  />
                </div>
                <div className="col-md-3">
                  <Input
                    label="Valor"
                    name="valor"
                    placeholder="Informe o valor"
                    msgErro={errors.valor?.message}
                    register={register}
                    required="Valor é obrigatório"
                    style={{ textAlign: 'right' }}
                    disabled
                  />
                </div>
              </div>
              {saboresCombo && (
                <div className="form-row">
                  <div className="col-md-6">
                    <SelectSearch
                      label="Sabores"
                      name="itemPedidosabor.sabor.id"
                      options={saboresCombo}
                      msgErro={errors.sabor?.id?.message}
                      register={register}
                      required={{ value: true, message: "Sabor é obrigatório" }}
                      onChange={handleSaborChange}
                    />
                  </div>
                  <button type="button" onClick={insertSabor} className="btn btn-success">
                    Adicionar Sabor
                  </button>
                </div>
              )}

              <div className="form-row">
                {pedidoItemSabores.length > 0 && (
                  <div className="col-md-12">
                    <table className="table table-striped projects">
                      <thead>
                        <tr>
                          <th style={{ width: "10%" }}>Código</th>
                          <th style={{ width: "30%" }}>Descrição</th>
                          <th style={{ width: "30%" }}></th>
                        </tr>
                      </thead>
                      <tbody>
                        {pedidoItemSabores.map((pedidoItemSabor, index) => (
                          <tr key={index}>
                            <td>{pedidoItemSabor.sabor.codSabor}</td>
                            <td>{pedidoItemSabor.sabor.descricao}</td>
                            <td className="project-actions text-right">
                              
                              <button
                                type="button"
                                className="btn btn-danger btn-sm"
                                onClick={() => deleteSabor(pedidoItemSabor)}
                              >
                                <i className="fas fa-trash"></i> Excluir
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
              <InputMultiline
                label="Observação"
                name="observacao"
                rows={3}
                placeholder="Informe uma observação"
                msgErro={errors.observacao?.message}
                register={register}
              />
            </div>
            <div className="modal-footer flex-footer">
              <button type="button" className="btn btn-secondary" onClick={onClose}>
                Cancelar
              </button>
              <button
                type="submit"
                className="btn btn-success"
                disabled={saboresCombo && pedidoItemSabores.length === 0}
              >
                Gravar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};


export default ModalItemPedido;
