// utils/numberUtils.js

export function formatCurrency(value) {
    if (value !== null && value !== undefined) {
        return `R$ ${value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ".").replace('.', ',')}`;
    } else {
        return "R$ 0,00";
    }
}

export function parseCurrency(value) {
    if (typeof value === "string") {
        value = value.replace("R$ ", "").replace(/\./g, "").replace(",", ".");
    }
    return parseFloat(value) || 0;
}
