import React from 'react';
import FormCad from '../../../components/FormCad';
import Input from '../../../components/form/input/Input';
import { useForm } from 'react-hook-form';
import { postData, putData } from '../../../services/Api';

function CadUsuario({ usuarioData, onClose }) {
  if (!usuarioData) {
    usuarioData = {
      id: 0,
      nome: "",
      login: "",
      senha: "",
    };
  }

  const { register, formState: { errors }, handleSubmit } = useForm({
    defaultValues: usuarioData,
  });

  const onSubmit = async (data) => {
    try {
      if (usuarioData.id !== 0) {
        const response = await putData('/Usuario', data);
        console.log("Resposta da API: ", response);
      } else {
        const response = await postData('/Usuario', data);
        console.log("Resposta da API: ", response);
      }
      onClose(); // Fechar a tela de cadastro após o envio bem-sucedido
    } catch (error) {
      console.error("Erro ao gravar dados: ", error);
      // Exibir mensagem de erro, se necessário
    }
  };

  return (
    <FormCad
      cabecalho="Cadastro de Usuários"
      titulo={usuarioData.id === 0 ? "Cadastrar Novo Usuário" : "Alterar Usuário"}
      nameBtnGravar="Gravar"
      cancelarClick={onClose}
      gravarClick={handleSubmit(onSubmit)}
    >
      <form>
        <div className="card-body">
        <Input
            label="Nome Completo"
            name="Nome"
            placeholder="Informe o Nome do Usuário"
            msgErro={errors.nome?.message}
            register={register}
            required="Nome do usuário é obrigatório"
          />
          <Input
            label="Login"
            name="login"
            placeholder="Informe o Login do Usuário"
            msgErro={errors.login?.message}
            register={register}
            required="Login do usuário é obrigatório"
          />
          <Input
            label="Senha"
            name="senha"
            type="password"
            placeholder="Informe a Senha do Usuário"
            msgErro={errors.senha?.message}
            register={register}
            required="Senha do usuário é obrigatória"
          />
        </div>
      </form>
    </FormCad>
  );
}

export default CadUsuario;
