import React from 'react';
import ReactSelect from 'react-select';

const SelectSearch = ({ label, name, options, valorPadrao, register, required, msgErro, onChange }) => {
    const formattedOptions = options.map(option => ({
        value: option.id,
        label: option.descricao
    }));

    const defaultValue = formattedOptions.find(option => option.value === valorPadrao);

    return (
        <div className="form-group">
            <label>{label}</label>
            <ReactSelect
                name={name}
                defaultValue={defaultValue}
                options={formattedOptions}
                onChange={onChange}
                className={!msgErro ? "basic-single" : "basic-single is-invalid"}
                classNamePrefix="select"
                isSearchable={true}
                // O react-hook-form pode precisar de setup adicional
            />
            {msgErro && <span className="error invalid-feedback">{msgErro}</span>}
        </div>
    );
};

export default SelectSearch;
