import React, { useEffect, useState } from 'react';
import Input from '../../../components/form/input/Input';
import { useForm } from 'react-hook-form';
import { fetchData, putData } from '../../../services/Api';
import FormCad from '../../../components/FormCad';

function CadConfigPedido({ configData, onClose }) {
  const [dataLoaded, setDataLoaded] = useState(false);
  const { register, formState: { errors }, handleSubmit, setValue } = useForm({
    defaultValues: configData,
  });

  useEffect(() => {
    async function fetchConfigData() {
      if (!configData) {
        try {
          const result = await fetchData('/ConfigPedido');
          configData = result;
          console.log(result);

          // Atualiza os valores do formulário com os dados buscados
          Object.keys(result).forEach(key => {
            setValue(key, result[key]);
          });

          setDataLoaded(true);
        } catch (error) {
          console.error("Erro ao buscar configuração de pedido:", error);
        }
      } else {
        // Se configData já estiver disponível
        Object.keys(configData).forEach(key => {
          setValue(key, configData[key]);
        });
        setDataLoaded(true);
      }
    }

    fetchConfigData();
  }, [configData, setValue]);

  const onSubmit = async (data) => {
    try {
      // Prepara o payload para enviar à API
      const payload = {
        id: data?.id || 0,
        previsaoMin: data.previsaoMin, // Minutos de previsão
      };

      const response = await putData('/ConfigPedido', payload);
      console.log("Resposta da API: ", response);
      alert("Configuração de Pedido alterada!");
    } catch (error) {
      console.error("Erro ao gravar configuração: ", error);
    }
  };

  if (!dataLoaded) {
    return <div>Carregando...</div>; // Renderiza um estado de carregamento
  }

  return (
    <FormCad
      cabecalho="Configuração de Pedido"
      titulo={configData?.id === 0 ? "Cadastrar Nova Configuração" : "Alterar Configuração"}
      nameBtnGravar="Gravar"
      cancelarClick={onClose}
      gravarClick={handleSubmit(onSubmit)}
    >
      <form>
        <div className="card-body">
          <Input
            label="Previsão em Minutos"
            name="previsaoMin"
            placeholder="Informe a previsão em minutos"
            type="number"
            msgErro={errors.previsaoMin?.message}
            register={register}
            required="Previsão é obrigatória"
          />
        </div>
      </form>
    </FormCad>
  );
}

export default CadConfigPedido;
