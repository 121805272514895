import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import FormCad from '../../components/FormCad';
import Input from '../../components/form/input/Input';
import { fetchData, postData, putData } from '../../services/Api';

function CadTipoEntrega({ tipoEntregaData, onClose }) {
  const [taxaEntrega, setTaxaEntrega] = useState(tipoEntregaData?.taxaEntrega || '');
  
  if (!tipoEntregaData) {
    tipoEntregaData = {
      id: 0,
      descricao: '',
      taxaEntrega: 0
    };
  }

  const { register, formState: { errors }, handleSubmit, setValue } = useForm({
    defaultValues: tipoEntregaData,
  });

  useEffect(() => {
    if (tipoEntregaData) {
      setValue('descricao', tipoEntregaData.descricao);
      setValue('taxaEntrega', tipoEntregaData.taxaEntrega);
    }
  }, [tipoEntregaData, setValue]);

  const onSubmit = async (data) => {
    try {
      data.taxaEntrega = parseFloat(taxaEntrega);

      if (tipoEntregaData.id !== 0) {
        const response = await putData(`/tipoEntrega`, data);
        console.log('Resposta da API: ', response);
      } else {
        const response = await postData(`/tipoEntrega`, data);
        console.log('Resposta da API: ', response);
      }
      onClose();
    } catch (error) {
      console.error('Erro ao gravar dados: ', error);
    }
  };

  return (
    <FormCad
      cabecalho="Cadastro de Tipos de Entrega"
      titulo={tipoEntregaData.id === 0 ? 'Cadastrar Novo Tipo de Entrega' : 'Alterar Tipo de Entrega'}
      nameBtnGravar="Gravar"
      cancelarClick={onClose}
      gravarClick={handleSubmit(onSubmit)}
    >
      <form>
        <div className="card-body">
          <Input
            label="Descrição"
            name="descricao"
            placeholder="Informe a Descrição do Tipo de Entrega"
            msgErro={errors.descricao?.message}
            register={register}
            required="Descrição do tipo de entrega é obrigatória"
          />
          <Input
            label="Taxa de Entrega"
            name="taxaEntrega"
            placeholder="Informe a Taxa de Entrega"
            msgErro={errors.taxaEntrega?.message}
            register={register}
            required="Taxa de entrega é obrigatória"
            value={taxaEntrega}
            onChange={(e) => setTaxaEntrega(e.target.value)}
          />
        </div>
      </form>
    </FormCad>
  );
}

export default CadTipoEntrega;
