import React from "react";
import { Link } from "react-router-dom";
import './SideNav.css';  
import { useUser } from '../contexts/UserContext'; // Importa o hook useUser

function SideNav() {
  const { user } = useUser(); // Usa o contexto de usuário

  return (
    <div>
        {/* Main Sidebar Container */}
        <aside className="main-sidebar sidebar-dark-primary elevation-4">
        {/* Brand Logo */}
        <a href="http://www.infomaio.com.br" className="brand-link">            
            <span className="fontAppName"><b>InfoDelivery</b></span>
        </a>
        {/* Sidebar */}
        <div className="sidebar">
            {/* Sidebar user panel (optional) */}
            <div className="user-panel mt-3 pb-3 mb-3 d-flex">            
                <div className="info">
                    {/* Exibe o login do usuário a partir do contexto */}
                    <a href="#" className="d-block">{user || "Usuário"}</a>
                </div>
            </div>
            {/* SidebarSearch Form */}
            <div className="form-inline">
            <div className="input-group" data-widget="sidebar-search">
                <input className="form-control form-control-sidebar" type="search" placeholder="Buscar" aria-label="Search" />
                <div className="input-group-append">
                <button className="btn btn-sidebar">
                    <i className="fas fa-search fa-fw" />
                </button>
                </div>
            </div>
            </div>
            {/* Sidebar Menu */}
            <nav className="mt-2">
            <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">


            <li className="nav-item">
                <a href="#" className="nav-link">
                    <i className="nav-icon fas fa-book" />
                    <p>
                    Cadastros
                    <i className="fas fa-angle-left right" />
                    </p>
                </a>
                <ul className="nav nav-treeview">
                    <li className="nav-item">
                    <a href="/grupo" className="nav-link">
                        <i className="far fa-circle nav-icon" />
                        <p>Grupos</p>
                    </a>
                    </li>
                    <li className="nav-item">
                    <a href="/variacao" className="nav-link">
                        <i className="far fa-circle nav-icon" />
                        <p>Variações</p>
                    </a>
                    </li>
                    <li className="nav-item">
                    <a href="/sabor" className="nav-link">
                        <i className="far fa-circle nav-icon" />
                        <p>Sabores</p>
                    </a>
                    </li>
                    <li className="nav-item">                        
                    <a href="/produto" className="nav-link"> 
                        <i className="far fa-circle nav-icon" />
                        <p>Produtos</p>
                    </a>
                    </li>
                    <li className="nav-item">                        
                    <a href="/tipoEntrega" className="nav-link"> 
                        <i className="far fa-circle nav-icon" />
                        <p>Formas de Entrega</p>
                    </a>
                    </li>
                    <li className="nav-item">                        
                    <a href="/mensagens" className="nav-link"> 
                        <i className="far fa-circle nav-icon" />
                        <p>Mensagens Automáticas</p>
                    </a>
                    </li>
                    <li className="nav-item">                        
                    <a href="/configpedido" className="nav-link"> 
                        <i className="far fa-circle nav-icon" />
                        <p>Configurações</p>
                    </a>
                    </li>
                    <li className="nav-item">                        
                    <a href="/usuario" className="nav-link"> 
                        <i className="far fa-circle nav-icon" />
                        <p>Usuários</p>
                    </a>
                    </li>
                                      
                </ul>
                </li>                                                

                <li className="nav-item">
                <a href="#" className="nav-link">
                    <i className="nav-icon fas fa-book" />
                    <p>
                    Pedidos
                    <i className="fas fa-angle-left right" />
                    </p>
                </a>
                <ul className="nav nav-treeview">
                    <li className="nav-item">
                    <a href="/pedido" className="nav-link">
                        <i className="far fa-circle nav-icon" />
                        <p>Gerenciar Pedidos</p>
                    </a>
                    </li>
                    <li className="nav-item">
                    <a href="pages/examples/projects.html" className="nav-link">
                        <i className="far fa-circle nav-icon" />
                        <p>Relatório de Pedidos</p>
                    </a>
                    </li>                    
                </ul>
                </li>    
            </ul>
            </nav>
            {/* /.sidebar-menu */}
        </div>
        {/* /.sidebar */}
        </aside>
    </div>
  );
}

export default SideNav;
