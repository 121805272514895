import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ModalYesNo from '../../../components/form/modal/ModalYesNo';
import FormVazio from '../../../components/form/FormVazio';
import CadSabor from './CadSabor';
import { fetchData, deleteData } from '../../../services/Api';

function ViewSabor() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isCadastroOpen, setIsCadastroOpen] = useState(false);
    const [sabores, setSabores] = useState([]);
    const [saborSel, setSaborSel] = useState(null);
    const [tipoSaborFiltro, setTipoSaborFiltro] = useState(""); // Estado para o filtro de tipo de sabor
    const [descricaoFiltro, setDescricaoFiltro] = useState(""); // Para o filtro de descrição
    const [grupoFiltro, setGrupoFiltro] = useState(""); // Para o filtro de grupo
    const [grupos, setGrupos] = useState([]); // Para armazenar grupos
    
    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(-1);
    };

    useEffect(() => {
        const fetchDataFromAPI = async () => {
            try {
                const result = await fetchData('/Sabor');
                setSabores(result);

                // Obter grupos
                const gruposResult = await fetchData('/Grupo');
                setGrupos(gruposResult);
            } catch (error) {
                console.error("Erro ao buscar dados:", error);
            }
        };
        fetchDataFromAPI();
    }, []);

    const closeModal = () => {
        setIsModalOpen(false);
        setSaborSel(null);
    };

    const excluirRegistro = (sabor) => {
        setSaborSel(sabor);
        setIsModalOpen(true);
    };

    const handleModalConfirm = async () => {
        try {
            const idSabor = saborSel.id;
            console.log(idSabor);
            await deleteData('/Sabor', { saborId: idSabor });

            setSabores(sabores.filter(sabor => sabor.id !== idSabor));
            console.log('Sabor ' + idSabor + ' deletado com sucesso');
        } catch (error) {
            console.error('Erro ao deletar o sabor:', error);
        } finally {
            closeModal();
        }
    };

    const openCadastroInc = () => {
        setSaborSel(null);
        setIsCadastroOpen(true);
    };

    const openCadastroAlt = (sabor) => {
        setSaborSel(sabor);
        setIsCadastroOpen(true);
    };

    const closeCadastro = () => {
        setIsCadastroOpen(false);

        // Atualizar lista de sabores após operação
        const fetchDataFromAPI = async () => {
            try {
                const result = await fetchData('/Sabor');
                setSabores(result);
            } catch (error) {
                console.error("Erro ao buscar sabores:", error);
            }
        };
        fetchDataFromAPI();
    };

    // Funções de manipulação de mudança dos filtros
    const handleFiltroTipoSaborChange = (e) => {
        setTipoSaborFiltro(e.target.value);
    };    
    const handleFiltroDescricaoChange = (e) => {
        setDescricaoFiltro(e.target.value);
    };

    const handleFiltroGrupoChange = (e) => {
        setGrupoFiltro(e.target.value);
    };

    // Filtragem dos sabores com base nos filtros de descrição e grupo
    const saboresFiltrados = sabores.filter(sabor => {
        return (
            sabor.descricao.toLowerCase().includes(descricaoFiltro.toLowerCase()) &&
            (grupoFiltro === "" || sabor.grupo.id === parseInt(grupoFiltro)) &&
            (tipoSaborFiltro === "" || sabor.tipoSaborDesc === tipoSaborFiltro)
        );
    });
    

    return (
        <>
            {isCadastroOpen ? (
                <CadSabor saborData={saborSel} onClose={closeCadastro} />
            ) : (
                <FormVazio cabecalho="Cadastro de Sabores">
                    <section className="content">
                        <div className="card-header">
                            <button className="btn btn-secondary float" onClick={handleGoBack}>Voltar</button>
                            <button className="btn btn-success float-right" onClick={openCadastroInc}>Incluir Sabor</button>
                        </div>

                        <br />

                        <div className="card-body p-0">
                            <div className="row mb-3">
                            <div className="col-md-3">
                                <select 
                                    className="form-control" 
                                    value={tipoSaborFiltro} 
                                    onChange={handleFiltroTipoSaborChange}
                                >
                                    <option value="">Filtrar por tipo de sabor</option>
                                    <option value="Salgado">Salgado</option>
                                    <option value="Doce">Doce</option>
                                    {/* Adicione mais tipos se existirem */}
                                </select>
                            </div>

                                <div className="col-md-6">
                                    <input 
                                        type="text" 
                                        placeholder="Filtrar por descrição" 
                                        className="form-control" 
                                        value={descricaoFiltro} 
                                        onChange={handleFiltroDescricaoChange} 
                                    />
                                </div>
                                <div className="col-md-3">
                                    <select 
                                        className="form-control" 
                                        value={grupoFiltro} 
                                        onChange={handleFiltroGrupoChange}
                                    >
                                        <option value="">Filtrar por grupo</option>
                                        {grupos.map(grupo => (
                                            <option key={grupo.id} value={grupo.id}>
                                                {grupo.descricao}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>

                            <table className="table table-striped projects">
                                <thead>
                                    <tr>
                                        <th style={{ width: '5%' }}>Tipo</th>
                                        <th style={{ width: '5%' }}>Código</th>
                                        <th style={{ width: '10%' }}>Grupo</th>
                                        <th style={{ width: '20%' }}>Descrição</th>
                                        <th style={{ width: '55%' }}>Ingredientes</th>
                                        <th style={{ width: '20%' }}></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {saboresFiltrados.map((sabor) => (
                                        <tr key={sabor.id}>
                                            <td>
                                                <a><b>{sabor.tipoSaborDesc}</b></a>
                                            </td>
                                            <td>
                                                <a><b>{sabor.codSabor}</b></a>
                                            </td>
                                            <td>
                                                <a>{sabor.grupo.descricao}</a>
                                            </td>
                                            <td>
                                                <a>{sabor.descricao}</a>
                                            </td>
                                            <td>
                                                <a>{sabor.ingredientes}</a>
                                            </td>
                                            <td className="project-actions text-right">
                                                <a className="btn btn-info btn-sm" onClick={() => openCadastroAlt(sabor)}>
                                                    <i className="fas fa-pencil-alt"></i>
                                                    Editar
                                                </a>
                                                <a className="btn btn-danger btn-sm" onClick={() => excluirRegistro(sabor)}>
                                                    <i className="fas fa-trash"></i>
                                                    Excluir
                                                </a>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </section>

                    <ModalYesNo isOpen={isModalOpen} onClose={closeModal} onConfirm={handleModalConfirm}>
                        <span>Deseja realmente excluir o sabor selecionado?</span>
                    </ModalYesNo>
                </FormVazio>
            )}
        </>
    );
}

export default ViewSabor;
