// dateTimeUtils.js

/**
 * Formata uma data ou string de data/hora para o formato HH:mm
 * @param {Date | string} datetime - O objeto Date ou string.
 * @returns {string} - A hora formatada como HH:mm.
 */
export const formatTime = (datetime) => {
    const date = new Date(datetime);
    const hours = String(date.getHours()).padStart(2, '0'); // Garante dois dígitos
    const minutes = String(date.getMinutes()).padStart(2, '0'); // Garante dois dígitos
    return `${hours}:${minutes}`;
};

/**
 * Formata uma data ou string de data/hora para o formato dd/mm/yyyy
 * @param {Date | string} datetime - O objeto Date ou string.
 * @returns {string} - A data formatada como dd/mm/yyyy.
 */
export const formatDate = (datetime) => {
    const date = new Date(datetime);
    const day = String(date.getDate()).padStart(2, '0'); // Garante dois dígitos
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Meses começam do zero
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
};
