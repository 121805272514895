import React, { useEffect, useState } from "react";
import FormCad from "../../../components/FormCad";
import Input from "../../../components/form/input/Input";
import InputMultiline from "../../../components/form/input/InputMultiline";
import { useForm, Controller } from "react-hook-form";
import { fetchData, postData, putData } from "../../../services/Api";
import Select from "../../../components/form/input/Select";
import InputMoney from "../../../components/form/input/InputMoney";
import { parseCurrency } from "../../../utils/numberUtils";

function CadProduto({ produtoData, onClose }) {
  const [grupos, setGrupos] = useState([]);
  const [grupoId, setGrupoId] = useState(produtoData?.grupo?.id || null);
  const [isQtdeSaborVisible, setIsQtdeSaborVisible] = useState(false); // Estado para visibilidade
  const [variacoes, setVariacoes] = useState([]);
  const [imagemPreview, setImagemPreview] = useState(produtoData?.imagem?.arquivo ? `data:image/jpeg;base64,${produtoData.imagem.arquivo}` : ""); 
  const [imagemDetalhes, setImagemDetalhes] = useState({ nome: "", tamanho: 0, arquivo: "" });

  if (!produtoData) {
    produtoData = {
      id: 0,
      descricao: "",
      grupo: { id: 0, descricao: "" },
      variacao: { id: 0, descricao: "" },
      valor: null,
      qtdeSabor: null,
      imagem: null
    };
  }

  const { register, formState: { errors }, handleSubmit, control, setValue } = useForm({
    defaultValues: produtoData,
  });

  useEffect(() => {
    const fetchDataFromAPI = async () => {
      try {
        const result = await fetchData("/Grupo");
        setGrupos(result);
      } catch (error) {
        console.error("Erro ao buscar grupos:", error);
      }
    };
    fetchDataFromAPI();
  }, []);

  useEffect(() => {
    const fetchDataFromAPI = async () => {
      try {
        if (grupoId) {
          const result = await fetchData("/Variacao/GetByGrupo", { grupoId });
          setVariacoes(result);
        }
      } catch (error) {
        console.error("Erro ao buscar variações:", error);
      }
    };
    fetchDataFromAPI();
  }, [grupoId]);

  useEffect(() => {
    const foundGroup = grupos.find(g => g.id === parseInt(grupoId, 10));
    if (foundGroup) {
      if (foundGroup.temSabor === 1) {
        setIsQtdeSaborVisible(true);
      } else {
        setIsQtdeSaborVisible(false);
      }
    } else {
      setIsQtdeSaborVisible(false);
    }
  }, [grupoId, grupos]);

  const handleGrupoChange = (e) => {
    const selectedGroupId = e.target.value;
    setGrupoId(selectedGroupId);
    setValue("grupo.id", selectedGroupId); // Atualizando valor no formulário
  };

  const handleImagemChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result.split(',')[1];
        setImagemDetalhes({
          nome: file.name,
          tamanho: file.size,
          arquivo: base64String
        });
        setImagemPreview(URL.createObjectURL(file));
      };
      reader.readAsDataURL(file);
    }
  };

  const onSubmit = async (data) => {
    try {
      data.qtdeSabor = parseInt(data.qtdeSabor, 10);
      data.valor = parseCurrency(data.valor);

      if (imagemDetalhes.arquivo) {
        data.imagem = {
          id: 0,
          nome: imagemDetalhes.nome,
          tamanho: imagemDetalhes.tamanho,
          arquivo: imagemDetalhes.arquivo
        };
      }

      let response;
      if (produtoData.id !== 0) {
        response = await putData("/Produto", data);
      } else {
        response = await postData("/Produto", data);
      }
      console.log("Resposta da API: ", response);
      onClose(); // Fechar a tela de cadastro após o envio bem-sucedido
    } catch (error) {
      console.error("Erro ao gravar dados: ", error);
    }
  };

  return (
    <FormCad
      cabecalho="Cadastro de Produtos"
      titulo={produtoData.id === 0 ? "Cadastrar Novo Produto" : "Alterar Produto"}
      nameBtnGravar="Gravar"
      cancelarClick={onClose}
      gravarClick={handleSubmit(onSubmit)}
    >
      <form>
        <div className="card-body">
          <Input
            label="Descrição"
            name="descricao"
            placeholder="Informe a Descrição do Produto"
            msgErro={errors.descricao?.message}
            register={register}
            required="Descrição do produto é obrigatória"
          />
          <InputMultiline
            label="Ingredientes"
            name="ingredientes"
            rows={3}
            placeholder="Informe os ingredientes do Produto"
            //msgErro={errors.descricao?.message}
            register={register}
            //required="Descrição do produto é obrigatória"
          />
          <Select
            label="Grupo"
            name="grupo.id"
            options={grupos}
            valorPadrao={produtoData.grupo.id}
            msgErro={errors.grupo?.id?.message}
            register={register}
            required={{ value: true, message: "Grupo é obrigatório" }}
            onChange={handleGrupoChange}
          />
          <Select
            label="Variação"
            name="variacao.id"
            options={variacoes}
            valorPadrao={produtoData.variacao.id}
            msgErro={errors.variacao?.id?.message}
            register={register}
            required={{ value: true, message: "Variação é obrigatória" }}
          />
          <InputMoney
            label="Valor"
            name="valor"
            placeholder="Informe um valor para o Produto"
            control={control}
            required
            error={errors.valor}
          />
          {/* Verifica se o campo qtdeSabor deve ser visível */}
          {isQtdeSaborVisible && (
            <Input
              label="Qtde. Sabores"
              name="qtdeSabor"
              placeholder="Informe a quantidade de Sabores"
              register={register}
            />
          )}
          <Controller
            name="imagem.arquivo"
            control={control}
            render={({ field }) => (
              <div className="form-group">
                <label>Imagem:</label>
                <div className="d-flex align-items-center">
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => handleImagemChange(e)}
                  />
                  <input
                    type="text"
                    className="form-control ml-2"
                    readOnly
                    value={imagemDetalhes.nome}
                  />
                </div>
                {imagemPreview && (
                  <div className="mt-2">
                    <img src={imagemPreview} alt="Pré-visualização" style={{ maxHeight: '200px' }} />
                  </div>
                )}
              </div>
            )}
          />
        </div>
      </form>
    </FormCad>
  );
}

export default CadProduto;
