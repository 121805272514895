import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Importar o useNavigate
import ModalYesNo from '../../../components/form/modal/ModalYesNo';
import FormVazio from '../../../components/form/FormVazio';
import CadProduto from './CadProduto';
import { fetchData, deleteData } from '../../../services/Api';
import { formatCurrency } from '../../../utils/numberUtils';  // Importando a função de formatação

function ViewProduto() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isCadastroOpen, setIsCadastroOpen] = useState(false);
    const [produtos, setProdutos] = useState([]);
    const [produtoSel, setProdutoSel] = useState(null);
    const [descricaoFiltro, setDescricaoFiltro] = useState(""); // Estado para o filtro de descrição
    const [grupoFiltro, setGrupoFiltro] = useState(""); // Estado para o filtro de grupo
    const [grupos, setGrupos] = useState([]); // Estado para armazenar os grupos

    const navigate = useNavigate(); // Definir o hook useNavigate
    const handleGoBack = () => {        
        navigate(-1); // Navegar para a tela anterior                
    };


    useEffect(() => {
        const fetchDataFromAPI = async () => {
            try {
                const result = await fetchData('/Produto');
                setProdutos(result);

                const gruposResult = await fetchData('/Grupo');
                setGrupos(gruposResult);
            } catch (error) {
                console.error("Erro ao buscar produtos:", error);
            }
        };
        fetchDataFromAPI();
    }, []);

    const closeModal = () => {
        setIsModalOpen(false);
        setProdutoSel(null); // Limpa o ID do grupo a ser excluído ao fechar a modal
    };

    const excluirRegistro = (produto) => {
        setProdutoSel(produto); // Armazenar o ID do produto a ser excluído        
        setIsModalOpen(true);
    };

    const handleModalConfirm = async () => {
        try {
            const produtoId = produtoSel.id;
            await deleteData('/Produto', { produtoId }); 
            setProdutos(produtos.filter(prod => prod.id !== produtoId)); 
        }  finally {
            closeModal(); 
        }
    };

    const openCadastroInc = () => {
        setProdutoSel(null);
        setIsCadastroOpen(true);
    };

    const openCadastroAlt = (produto) => {
        setProdutoSel(produto);
        setIsCadastroOpen(true);
    };

    const closeCadastro = () => {
        setIsCadastroOpen(false);        
        const fetchDataFromAPI = async () => {
            const result = await fetchData('/Produto');
            setProdutos(result);
        };
        fetchDataFromAPI();
    };
    

    const handleFiltroDescricaoChange = (e) => {
        setDescricaoFiltro(e.target.value);
    };

    const handleFiltroGrupoChange = (e) => {
        setGrupoFiltro(e.target.value);
    };

    // Filtra os produtos com base nos filtros de descrição e grupo
    const produtosFiltrados = produtos.filter(produto => {
        return (
            produto.descricao.toLowerCase().includes(descricaoFiltro.toLowerCase()) &&
            (grupoFiltro === "" || produto.grupo.id === parseInt(grupoFiltro))
        );
    });

    return (
        <>
            {isCadastroOpen ? (
                <CadProduto produtoData={produtoSel} onClose={closeCadastro} />
            ) : (
                <FormVazio cabecalho="Cadastro de Produtos">
                    <section className="content">
                        <div className="card-header">
                            <button className="btn btn-secondary float" onClick={handleGoBack}>Voltar</button>
                            <button className="btn btn-success float-right" onClick={openCadastroInc}>Incluir Produto</button>
                        </div>

                        <br />

                        <div className="card-body p-0">
                            <div className="row mb-3">
                                <div className="col-md-6">
                                    <input 
                                        type="text" 
                                        placeholder="Filtrar por descrição" 
                                        className="form-control" 
                                        value={descricaoFiltro} 
                                        onChange={handleFiltroDescricaoChange} 
                                    />
                                </div>
                                <div className="col-md-6">
                                    <select 
                                        className="form-control" 
                                        value={grupoFiltro} 
                                        onChange={handleFiltroGrupoChange}
                                    >
                                        <option value="">Filtrar por grupo</option>
                                        {grupos.map(grupo => (
                                            <option key={grupo.id} value={grupo.id}>
                                                {grupo.descricao}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>

                            <table className="table table-striped projects">
                                <thead>
                                    <tr>
                                        <th style={{ width: '10%' }}>Id</th>
                                        <th style={{ width: '20%' }}>Descrição</th>
                                        <th style={{ width: '20%' }}>Variação</th>
                                        <th style={{ width: '20%' }}>Grupo</th>
                                        <th style={{ width: '10%' }}>Valor</th>
                                        <th style={{ width: '20%' }}></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {produtosFiltrados.map((produto) => (
                                        <tr key={produto.id}>
                                            <td>
                                                <a><b>{produto.id}</b></a>
                                            </td>
                                            <td>
                                                <a>{produto.descricao}</a>
                                            </td>
                                            <td>
                                                <a>{produto.variacao.descricao}</a>
                                            </td>
                                            <td>
                                                <a>{produto.grupo.descricao}</a>
                                            </td>
                                            <td>
                                                <a>{formatCurrency(produto.valor)}</a>
                                            </td>
                                            <td className="project-actions text-right">
                                                <a className="btn btn-info btn-sm" onClick={() => openCadastroAlt(produto)}>
                                                    <i className="fas fa-pencil-alt"></i>
                                                    Editar
                                                </a>
                                                <a className="btn btn-danger btn-sm" onClick={() => excluirRegistro(produto)}>
                                                    <i className="fas fa-trash"></i>
                                                    Excluir
                                                </a>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </section>

                    <ModalYesNo isOpen={isModalOpen} onClose={closeModal} onConfirm={handleModalConfirm}>
                        <span>Deseja realmente excluir o produto selecionado?</span>
                    </ModalYesNo>
                </FormVazio>
            )}
        </>
    );
}

export default ViewProduto;
