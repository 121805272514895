import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Importar o useNavigate
import ModalYesNo from '../../components/form/modal/ModalYesNo';
import FormVazio from '../../components/form/FormVazio';
import CadTipoEntrega from './CadTipoEntrega'; // Componente para cadastro de Tipo de Entrega
import { fetchData, deleteData } from '../../services/Api';
import { formatCurrency } from '../../utils/numberUtils';

function ViewTipoEntrega() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isCadastroOpen, setIsCadastroOpen] = useState(false);
    const [tiposEntrega, setTiposEntrega] = useState([]);
    const [tipoEntregaSel, setTipoEntregaSel] = useState(null);

    const navigate = useNavigate(); // Definir o hook useNavigate
    const handleGoBack = () => {
        navigate(-1); // Navegar para a tela anterior    
    };

    useEffect(() => {
        const fetchDataFromAPI = async () => {
            try {
                const result = await fetchData('/TipoEntrega');
                setTiposEntrega(result);
            } catch (error) {
                console.error("Erro ao buscar tipos de entrega:", error);
            }
        };
        fetchDataFromAPI();
    }, []);

    const closeModal = () => {
        setIsModalOpen(false);
        setTipoEntregaSel(null); // Limpa o ID do tipo de entrega a ser excluído ao fechar a modal
    };

    const excluirRegistro = (tipoEntrega) => {
        setTipoEntregaSel(tipoEntrega); // Armazenar o ID do tipo de entrega a ser excluído        
        setIsModalOpen(true);
    };

    const handleModalConfirm = async () => {
        try {
            const idTipoEntrega = tipoEntregaSel.id; // ID do tipo de entrega a ser excluído
            await deleteData('/TipoEntrega', { id: idTipoEntrega }); // Exclusão do tipo de entrega no servidor

            setTiposEntrega(tiposEntrega.filter(tipo => tipo.id !== idTipoEntrega)); // Atualiza a lista de tipos de entrega
            console.log('Tipo de entrega ' + idTipoEntrega + ' deletado com sucesso');
        } catch (error) {
            console.error('Erro ao deletar o tipo de entrega:', error);
        } finally {
            closeModal(); // Fecha a modal e limpa o ID do tipo de entrega a ser excluído
        }
    };

    const openCadastroInc = () => {
        setTipoEntregaSel(null);
        setIsCadastroOpen(true);
    };

    const openCadastroAlt = (tipoEntrega) => {
        setTipoEntregaSel(tipoEntrega);
        setIsCadastroOpen(true);
    };

    const closeCadastro = () => {
        setIsCadastroOpen(false);
        // Recarregar a lista de tipos de entrega após o cadastro (se necessário)
        const fetchDataFromAPI = async () => {
            try {
                const result = await fetchData('/TipoEntrega');
                setTiposEntrega(result);
            } catch (error) {
                console.error("Erro ao buscar tipos de entrega:", error);
            }
        };
        fetchDataFromAPI();
    };

    return (
        <>
            {isCadastroOpen ? (
                <CadTipoEntrega tipoEntregaData={tipoEntregaSel} onClose={closeCadastro} />
            ) : (
                <FormVazio cabecalho="Cadastro de Tipos de Entrega">
                    <section className="content">
                        <div className="card-header">
                            <button className="btn btn-secondary float" onClick={handleGoBack}>Voltar</button>
                            <button className="btn btn-success float-right" onClick={openCadastroInc}>Incluir Tipo de Entrega</button>
                        </div>

                        <br />

                        {tiposEntrega && (
                            <div className="card-body p-0">
                                <table className="table table-striped projects">
                                    <thead>
                                        <tr>
                                            <th style={{ width: '10%' }}>Código</th>
                                            <th style={{ width: '40%' }}>Descrição</th>
                                            <th style={{ width: '20%' }}>Taxa de Entrega</th>
                                            <th style={{ width: '20%' }}></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tiposEntrega.map((tipo) => (
                                            <tr key={tipo.id}>
                                                <td>
                                                    <a><b>{tipo.id}</b></a>
                                                </td>
                                                <td>
                                                    <a>{tipo.descricao}</a>
                                                </td>
                                                <td>
                                                    <a>{formatCurrency(tipo.taxaEntrega)}</a>
                                                </td>
                                                <td className="project-actions text-right">
                                                    <a className="btn btn-info btn-sm" onClick={() => openCadastroAlt(tipo)}>
                                                        <i className="fas fa-pencil-alt"></i>
                                                        Editar
                                                    </a>
                                                    <a className="btn btn-danger btn-sm" onClick={() => excluirRegistro(tipo)}>
                                                        <i className="fas fa-trash"></i>
                                                        Excluir
                                                    </a>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </section>

                    <ModalYesNo isOpen={isModalOpen} onClose={closeModal} onConfirm={handleModalConfirm}>
                        <span>Deseja realmente excluir o tipo de entrega selecionado?</span>
                    </ModalYesNo>
                </FormVazio>
            )}
        </>
    );
}

export default ViewTipoEntrega;
