import React from 'react';
import FormCad from '../../../components/FormCad';
import Input from '../../../components/form/input/Input';
import { useForm } from 'react-hook-form';
import { postData, putData } from '../../../services/Api';

function CadGrupo({ grupoData, onClose }) {
  if (!grupoData) {
    grupoData = {
      id: 0,
      descricao: "",
    };
  }

  const { register, formState: { errors }, handleSubmit, setValue } = useForm({
    defaultValues: grupoData,
  });

  const onSubmit = async (data) => {
    try {
      if (grupoData.id !== 0) {
        const response = await putData('/Grupo', data);
        console.log("Resposta da API: ", response);
      } else {
        const response = await postData('/Grupo', data);
        console.log("Resposta da API: ", response);
      }
      onClose(); // Fechar a tela de cadastro após o envio bem-sucedido
    } catch (error) {
      console.error("Erro ao gravar dados: ", error);
      // Você pode exibir uma mensagem de erro aqui, se necessário
    }
  };

  return (
    <FormCad
      cabecalho="Cadastro de Grupos"
      titulo={grupoData.id === 0 ? "Cadastrar Novo Grupo" : "Alterar Grupo"}
      nameBtnGravar="Gravar"
      cancelarClick={onClose}
      gravarClick={handleSubmit(onSubmit)}
    >
      <form>
        <div className="card-body">
          <Input
            label="Descrição"
            name="descricao"
            placeholder="Informe a Descrição do Grupo"
            msgErro={errors.descricao?.message} // Usar a mensagem de erro do hook form state
            register={register}
            required="Descrição do grupo é obrigatória" // Definir a validação diretamente no required
          />
        </div>
      </form>
    </FormCad>
  );
}

export default CadGrupo;
