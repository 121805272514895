import React, { useEffect, useState } from 'react';
import InputMultiline from '../../../components/form/input/InputMultiline';
import { useForm } from 'react-hook-form';
import { fetchData, putData } from '../../../services/Api';
import FormCad from '../../../components/FormCad';

function CadMensagem({ mensagemData, onClose }) {
  const [dataLoaded, setDataLoaded] = useState(false);
  const { register, formState: { errors }, handleSubmit, setValue } = useForm({
    defaultValues: mensagemData,
  });

  useEffect(() => {
    async function fetchMensagemData() {
      if (!mensagemData) {
        try {
          const result = await fetchData('/ChatBotWhatsapp');
          mensagemData = result;
          console.log(result);

          // Update the form values with the fetched data
          Object.keys(result).forEach(key => {
            setValue(key, result[key]);
          });

          setDataLoaded(true);
        } catch (error) {
          console.error("Erro ao buscar grupos:", error);
        }
      } else {
        // mensagemData is available
        Object.keys(mensagemData).forEach(key => {
          setValue(key, mensagemData[key]);
        });
        setDataLoaded(true);
      }
    }

    fetchMensagemData();
  }, [mensagemData, setValue]);

  const onSubmit = async (data) => {
    try {
      // Prepare data per the desired format
      const payload = {
        id: data?.id || 0,
        msgEmProducao: data.msgEmProducao,
        msgProntoBuscar: data.msgProntoBuscar,
        msgSaiuPEntrega: data.msgSaiuPEntrega,
        msgFinalizado: data.msgFinalizado,
      };

      const response = await putData('/ChatBotWhatsapp', payload);
      console.log("Resposta da API: ", response);
      alert("Mensagem alterada!");
    } catch (error) {
      console.error("Erro ao gravar dados: ", error);
    }
  };

  if (!dataLoaded) {
    return <div>Carregando...</div>; // You can render a loading state here
  }

  return (
    <FormCad
      cabecalho="Cadastro de Mensagens"
      titulo={mensagemData?.id === 0 ? "Cadastrar Nova Mensagem" : "Alterar Mensagem"}
      nameBtnGravar="Gravar"
      cancelarClick={onClose}
      gravarClick={handleSubmit(onSubmit)}
    >
      <form>
        <div className="card-body">
          <InputMultiline
            label="MsgEmProducao"
            name="msgEmProducao"
            placeholder="Informe a mensagem"
            rows={2}
            msgErro={errors.msgEmProducao?.message}
            register={register}
            required="Descrição da mensagem é obrigatória"
          />
          <InputMultiline
            label="MsgProntoBuscar"
            name="msgProntoBuscar"
            placeholder="Informe a mensagem"
            rows={2}
            msgErro={errors.msgProntoBuscar?.message}
            register={register}
            required="Descrição da mensagem é obrigatória"
          />
          <InputMultiline
            label="MsgSaiuPEntrega"
            name="msgSaiuPEntrega"
            placeholder="Informe a mensagem"
            rows={2}
            msgErro={errors.msgSaiuPEntrega?.message}
            register={register}
            required="Descrição da mensagem é obrigatória"
          />
          <InputMultiline
            label="MsgFinalizado"
            name="msgFinalizado"
            placeholder="Informe a mensagem"
            rows={2}
            msgErro={errors.msgFinalizado?.message}
            register={register}
            required="Descrição da mensagem é obrigatória"
          />
        </div>
      </form>
    </FormCad>
  );
}

export default CadMensagem;
