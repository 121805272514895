const Input = ({ label, name, placeholder, register, required, msgErro, disabled, style, onChange, type }) => (
  <div className="form-group">
    <label>{label}</label>
    <input      
      type={!type ? "text" : type} //Mandar password para senha
      className={!msgErro ? "form-control" : "form-control is-invalid"}
      aria-invalid={!msgErro ? "false" : "true"}
      placeholder={placeholder}
      {...register(name, { required })}
      disabled={disabled}
      style={style} // Alinhamento do texto à direita
      onChange={onChange}      
    />
    {msgErro && <span className="error invalid-feedback">{msgErro}</span>}
  </div>
);

export default Input;
