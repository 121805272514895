import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Input from "../../components/form/input/Input";
import { deleteData, fetchData, postData, putData } from "../../services/Api";
import Select from "../../components/form/input/Select";
import { formatCurrency } from "../../utils/numberUtils";
import ModalItemPedido from "./ModalItemPedido";
import ModalYesNo from "../../components/form/modal/ModalYesNo";
import InputHora from "../../components/form/input/InputHora";
import { handleEnviarPedido } from "../../components/handleEnviarPedido";

function CadPedido({ pedidoData, onClose, setIdNovoPedido }) {
  const [isModalWhatsAppOpen, setIsModalWhatsAppOpen] = useState(false);
  if (!pedidoData) {

    // Obtém a data e hora atual
    const now = new Date();
    
    // Adiciona 30 minutos
    now.setMinutes(now.getMinutes() + 30);
    
    // Formata a hora no formato HH:MM
    const formattedTime = now.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

    pedidoData = {
      id: 0,
      nomeCliente: "",
      statusPedido: { id: 3, descricao: "" },
      tipoEntrega: { id: 1, descricao: "", taxaEntrega: 0 },
      tipoPagamento: { id: 1, descricao: "" },
      enderecoCliente: "",
      dataHoraPrevisao: formattedTime,
      pedidoItens: [],
    };
  }

  const [selectedTipoEntrega, setSelectedTipoEntrega] = useState(
    pedidoData.tipoEntrega
  );
  const [tiposEntrega, setTiposEntrega] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalDeleteOpen, setIsModaDeletelOpen] = useState(false);
  const [pedidoId, setPedidoId] = useState(0);
  const [pedidoItemSel, setPedidoItemSel] = useState();
  const [pedidoItens, setPedidoItens] = useState([]);



  //const [taxaEntrega, setTaxaEntrega] = useState(0);

  useEffect(() => {
    const fetchTipoEntrega = async () => {
      try {
        const result = await fetchData("/TipoEntrega");
        setTiposEntrega(result);

        setPedidoItens(pedidoData.pedidoItens);
        setPedidoId(pedidoData.id);

        // Obtém a data e hora atual
        let now = new Date();
        let formattedTime="";
        
        if (pedidoData.id === 0) {
          // Adiciona 30 minutos
          now.setMinutes(now.getMinutes() + 30);
          formattedTime = now.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
          pedidoData.dataHoraPrevisao = formattedTime;  
        } else {     
          // Verifica a quantidade de caracteres da string dataHoraPrevisao
          const quantidadeCaracteres = pedidoData.dataHoraPrevisao ? pedidoData.dataHoraPrevisao.length : 0;          
          if (quantidadeCaracteres>5)      
          {
            now = new Date(pedidoData.dataHoraPrevisao); // Converte string para Date
            // Formata a hora no formato HH:MM
            formattedTime = now.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });   
            pedidoData.dataHoraPrevisao = formattedTime;     
          } else {
            formattedTime = pedidoData.dataHoraPrevisao;
          }
        }

        setValue('dataHoraPrevisao', formattedTime);
        reset(pedidoData);            
      } catch (error) {
        console.log("Erro ao buscar tipos de entrega:" + error);
      }
    
    };

    fetchTipoEntrega();
  }, []);

  const statusList = [
    { id: 1, descricao: "Cancelado" },
    { id: 2, descricao: "Em análise" },
    { id: 3, descricao: "Em Produção" },
    { id: 4, descricao: "Aguardando Retirada/Entrega" },
    { id: 5, descricao: "Concluído" },
  ];

  const tipoPagamentoList = [
    { id: 1, descricao: "Dinheiro" },
    { id: 2, descricao: "Pix" },
    { id: 3, descricao: "Cartão" },
  ];

  const handleTipoEntregaChange = (e) => {
    const selectedTipoEntregaId = parseInt(e.target.value, 10);
    const selected = tiposEntrega.find(
      (tipo) => tipo.id === selectedTipoEntregaId
    );

    //setValue("tipoEntrega.taxaEntrega", selected.taxaEntrega);

    setValue("tipoEntrega.taxaEntrega", selected.taxaEntrega);

    //setTaxaEntrega(selected.taxaEntrega);

    setSelectedTipoEntrega(selected);
  };

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = useForm({
    defaultValues: pedidoData,
  });

  //useEffect(() => {
  //  reset(pedidoData);
  //}, [pedidoData, reset]);

  const incItem = async (e) => {
    e.preventDefault(); // Certifique-se de que a capitalização está correta

    //setPedidoId(pedidoData.id);
    setPedidoItemSel(null);
    setIsModalOpen(true);
    //onClose(); // Certifique-se de que isto está comentado ou removido
  };

  const onSubmit = async (data) => {
    try {
      data.tipoEntrega = selectedTipoEntrega; // Assegura atualização do tipo de entrega
  
      if (pedidoItens && pedidoItens.length > 0) {
        let totalItens = pedidoItens.reduce((total, item) => total + item.valor, 0);
        data.valorItens = totalItens;
        data.taxaEntrega = data.tipoEntrega.taxaEntrega || 0;
        data.valorTotal = totalItens + data.taxaEntrega;
      }
  
      // Obtém a data atual
      const today = new Date();
  
      // Extrai a hora inserida no campo "dataHoraPrevisao"
      const [hour, minute] = data.dataHoraPrevisao.split(':');
  
      // Ajusta a data atual com a hora inserida
      today.setHours(hour-3);//gambia por que a hora vinha errada devido ao fuso
      today.setMinutes(minute);
      //today.setSeconds(0);
  
      // Converte para DateTime e adiciona ao objeto data
      data.dataHoraPrevisao = today.toISOString();
  
      console.log("Dados do pedido: ", data.dataHoraPrevisao);
  
      const response = pedidoId !== 0 
        ? await putData("/Pedido", { ...data, id: pedidoId }) 
        : await postData("/Pedido", data);
  
      const id = pedidoId || response.id;
      setIdNovoPedido(id);
      pedidoData.id = id;
      setPedidoId(id);
  
      if (pedidoItens && pedidoItens.length > 0) {
        setIsModalWhatsAppOpen(true);
      } else {
        setIsModalOpen(true);
      }
  
    } catch (error) {
      console.error("Erro ao gravar dados: ", error);
    }
  };
  
  const handleConfirmWhatsApp = async () => {
    const pedido = await fetchData("/Pedido/GetPedidoById", { pedidoId });
    pedidoData=pedido;
    const whatsappUrl = handleEnviarPedido(pedidoData); // Chame a função externa
    window.location.href = whatsappUrl; // Usa location.href para não abrir nova aba
    setIsModalWhatsAppOpen(false);
    onClose(); // Fecha o formulário após envio
  };

  const handleCancelWhatsApp = () => {
    setIsModalWhatsAppOpen(false);
    onClose(); // Fecha o formulário sem enviar para WhatsApp
  };
  
  //Controle da Modal dos Itens
  const openModal = async () => {
    try {
      setIsModalOpen(true);
    } catch (error) {
      console.error("Erro ao buscar detalhes do pedido:", error);
    }
  };

  const closeModal = async () => {
    setIsModalOpen(false);
    //Monta Grid ItemPedido
    const pedido = await fetchData("/Pedido/GetPedidoById", { pedidoId });
    pedidoData=pedido;
    setPedidoItens(pedido.pedidoItens);
  };

  //Deletar registro
  const closeModalDelete = () => {
    setIsModaDeletelOpen(false);
    setPedidoItemSel(null); // Limpa o ID do pedido a ser excluído ao fechar a modal
  };

  const excluirRegistro = (pedidoItem) => {
    setPedidoItemSel(pedidoItem); // Armazenar o ID do grupo a ser excluído
    setIsModaDeletelOpen(true);
  };

  const handleModalConfirm = async () => {
    try {
      await deleteData("/PedidoItem", { id: pedidoItemSel.id });

      const pedidos = pedidoItens.filter((pi) => pi.id !== pedidoItemSel.id);


      setPedidoItens(pedidos);

      //setPedidoItens(pedidoItens.filter(pi => pi.id !== pedidoItemSel.id));
      console.log("PedidoItem " + pedidoItemSel.id + " deletado com sucesso");
    } catch (error) {
      console.error("Erro ao deletar o PedidoItem:", error);
    } finally {
      closeModalDelete(); // Fecha a modal e limpa o ID do grupo a ser excluído
    }
  };

  const openCadastroAlt = (pedidoItem) => {
    setPedidoId(pedidoData.id);
    setPedidoItemSel(pedidoItem);
    setIsModalOpen(true);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        {" "}
        {/* Vinculando onSubmit ao formulário */}
        <div className="card-body">
          <div className="form-row">
            <div className="col-md-4">
              <Select
                label="Status Pedido"
                name="statusPedido.id"
                options={statusList}
                valorPadrao={pedidoData.statusPedido.id}
                msgErro={errors.statusPedido?.id?.message}
                register={register}
                required={{ value: true, message: "Status é obrigatório" }}
              />
            </div>
            <div className="col-md-8">
              <Input
                label="Cliente"
                name="nomeCliente"
                placeholder="Informe o cliente"
                msgErro={errors.nomeCliente?.message}
                register={register}
                required="Nome do cliente é obrigatório"
              />
            </div>
          </div>
          <div className="form-row mt-3">
            {" "}
            {/* Margem superior para espaçamento */}
            <div className="col-md-4">
              <Select
                label="Tipo Entrega"
                name="tipoEntrega.id"
                options={tiposEntrega}
                valorPadrao={pedidoData.tipoEntrega.id}
                msgErro={errors.tipoEntrega?.id?.message}
                register={register}
                //required={{ value: true, message: "Tipo de Entrega é obrigatório" }}
                onChange={handleTipoEntregaChange}
              />
            </div>
            {/* Condição para exibir a taxa de entrega */}
            {selectedTipoEntrega?.taxaEntrega > 0 && (
              <>
                <div className="col-md-3">
                  <Input
                    label="Taxa Entrega"
                    name="tipoEntrega.taxaEntrega"
                    placeholder="Informe a taxa"
                    msgErro={errors.taxaEntrega?.message}
                    register={register}
                    //required="Taxa de entrega é obrigatória"
                    value={formatCurrency(selectedTipoEntrega?.taxaEntrega)} // Atribuir o valor automaticamente
                    disabled
                    style={{ textAlign: "right" }} //Alinha a direita
                  />
                </div>
                <div className="col-md-10">
                  <Input
                    label="Endereço"
                    name="enderecoCliente"
                    placeholder="Informe o endereço"
                    msgErro={errors.enderecoCliente?.message}
                    register={register}
                    required="O endereço é obrigatório"
                    readOnly
                  />
                </div>
              </>
            )}
          </div>
          <div className="form-row">
            <div className="col-md-4">
              <Select
                label="Forma Pág"
                name="tipoPagamento.id"
                options={tipoPagamentoList}
                valorPadrao={pedidoData.tipoPagamento.id}
                msgErro={errors.tipoPagamento?.id?.message}
                register={register}
                required={{
                  value: true,
                  message: "Tipo pagamento é obrigatório",
                }}
              />
            </div>
            <div className="col-md-2">
                  <InputHora
                    label="Previsão"
                    name="dataHoraPrevisao"
                    placeholder="Informe horário"
                    msgErro={errors.dataHoraPrevisao?.message}
                    register={register}
                    required="Horário de previsão é obrigatório"
                    value={pedidoData.dataHoraPrevisao}
                  />
            </div>
          </div>
        </div>
        
        {pedidoId !== 0  && (
          <div className="card-body p-0">
            <button
              className="btn btn-secondary float-right"
              style={{ marginRight: "20px", marginBottom: "10px" }}
              onClick={incItem}
            >
              Incluir Item
            </button>
            {/**se não tiver dados dá problema no map */}
            {pedidoItens && pedidoItens.length > 0 && (

            <table className="table table-striped projects">
              <thead>
                <tr>
                  <th style={{ width: "10%" }}>Código</th>
                  <th style={{ width: "30%" }}>Descrição</th>
                  <th style={{ width: "10%" }}>Qtde</th>
                  <th style={{ width: "10%" }}>Valor</th>
                  <th style={{ width: "30%" }}></th>
                </tr>
              </thead>
              <tbody>
                {pedidoItens.map((pedidoItem) => (
                  <tr key={pedidoItem.id}>
                    <td>
                      <a>
                        <b>{pedidoItem.produto.id}</b>
                      </a>
                    </td>
                    <td>
                      <a>{pedidoItem.produto.descricao}</a>
                    </td>
                    <td>
                      <a>{pedidoItem.qtde}</a>
                    </td>
                    <td>
                      <a>{formatCurrency(pedidoItem.valor)}</a>
                    </td>
                    <td className="project-actions text-right">
                      <a
                        className="btn btn-info btn-sm"
                        onClick={() => openCadastroAlt(pedidoItem)}
                      >
                        <i className="fas fa-pencil-alt"></i>
                        Editar
                      </a>
                      <a
                        className="btn btn-danger btn-sm"
                        onClick={() => excluirRegistro(pedidoItem)}
                      >
                        <i className="fas fa-trash"></i>
                        Excluir
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            )}
          </div>
        )}
        <div className="card-footer">
          <button className="btn btn-outline-danger" onClick={onClose}>
            Cancelar
          </button>
          {pedidoItens && pedidoItens.length > 0 ? (
          <button type="submit" className="btn btn-success float-right">
            Concluir
          </button>
          ):(
            <button type="submit" className="btn btn-success float-right">
            Gravar
            </button>
          )}
        </div>
      </form>

      <ModalItemPedido
        isOpen={isModalOpen}
        onClose={closeModal}
        pedidoId={pedidoId}
        itemPedidoData={pedidoItemSel}
      />
      <ModalYesNo
        isOpen={isModalDeleteOpen}
        onClose={closeModalDelete}
        onConfirm={handleModalConfirm}
      >
        <span>Deseja realmente excluir o item selecionado?</span>
      </ModalYesNo>

      <ModalYesNo
        isOpen={isModalWhatsAppOpen}
        onClose={handleCancelWhatsApp}
        onConfirm={handleConfirmWhatsApp}
        >
        <span>Deseja encaminhar o pedido por WhatsApp?</span>
      </ModalYesNo>

    </>
  );
}

export default CadPedido;
