import React, {  } from "react";
//import { useNavigate } from "react-router-dom";


const FormCad = ({ children, conteudoHTML, cabecalho, titulo, cancelarClick, gravarClick, nameBtnGravar, classSize }) => {

  /*
  const navigate = useNavigate();
    const handleHome = (e) => {
        e.preventDefault();        
        navigate('/');
    };
    */

  return (
    
    <div>
      {/* Content Wrapper. Contains page content */}

      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>{cabecalho}</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  {/*
                  <li onClick={handleHome} className="breadcrumb-item"><a href="#">Home</a></li> 
                  */}
                <li className="breadcrumb-item"><a href="#">Home</a></li> 
                  <li className="breadcrumb-item active">Cadastros</li>
                </ol>
              </div>
            </div>
          </div>{/* /.container-fluid */}
        </section>

        {/* Main content */}
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              {/* left column */}
              <div className={classSize ? classSize : "col-md-6"} >
                {/* general form elements */}
                <div className="card card-primary">
                  <div className="card-header">
                    <h3 className="card-title">{titulo}</h3>
                  </div>
                  {/* /.card-header */}
                  {/* form start */}


      
                  { children }




                  <div className="card-footer">
                    <button className="btn btn-outline-danger" onClick={cancelarClick}>Cancelar</button>
                    <button className="btn btn-success float-right" onClick={gravarClick}>{nameBtnGravar?nameBtnGravar:"Gravar"}</button>
                  </div>                  
                </div>                           
              </div>
              {/*/.col (right) */}
            </div>
            {/* /.row */}
          </div>{/* /.container-fluid */}
        </section>
        {/* /.content */}
      </div>
      {/* /.content-wrapper */}

    </div>

  );
}

export default FormCad;
