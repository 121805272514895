import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import './App.css';
import { TokenProvider, useToken } from "./contexts/TokenContext";

import Login from "./components/Login";
import SideNav from './components/SideNav';
import Header from './components/Header';
import Dashboard from "./Dashboard";
import ViewGrupo from "./pages/cadastros/grupos/ViewGrupo";
import ViewVariacao from "./pages/cadastros/variacoes/ViewVariacao";
import ViewSabor from "./pages/cadastros/sabores/ViewSabor";
import ViewProduto from "./pages/cadastros/produtos/ViewProduto";
import ViewTipoEntrega from "./pages/pedidos/ViewTipoEntrega";
import CadMensagem from "./pages/cadastros/mensagens/CadMensagem";
import DashPedido from "./pages/pedidos/DashPedido";
import ViewUsuario from "./pages/cadastros/geral/ViewUsuario";
import CadConfigPedido from "./pages/cadastros/geral/CadConfigPedido";

// Um componente wrapper para rotas protegidas
const ProtectedRoute = ({ children }) => {
  const { token } = useToken();
  return (token !== 'null' && token) ? children : <Navigate to="/login" />;
};

function App() {
  const { token } = useToken();

  return (
    <div className="wrapper">
      {token !== 'null' && token && <Header />}
      {token !== 'null' && token && <SideNav />}

      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<Navigate to="/login" />}></Route>
          <Route path="/pedido" element={<ProtectedRoute><DashPedido /></ProtectedRoute>} />
          <Route path="/dash" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
          <Route path="/grupo" element={<ProtectedRoute><ViewGrupo /></ProtectedRoute>} />
          <Route path="/variacao" element={<ProtectedRoute><ViewVariacao /></ProtectedRoute>} />
          <Route path="/sabor" element={<ProtectedRoute><ViewSabor /></ProtectedRoute>} />
          <Route path="/produto" element={<ProtectedRoute><ViewProduto /></ProtectedRoute>} />
          <Route path="/tipoEntrega" element={<ProtectedRoute><ViewTipoEntrega /></ProtectedRoute>} />
          <Route path="/mensagens" element={<ProtectedRoute><CadMensagem /></ProtectedRoute>} />
          <Route path="/usuario" element={<ProtectedRoute><ViewUsuario /></ProtectedRoute>} />
          <Route path="/configpedido" element={<ProtectedRoute><CadConfigPedido /></ProtectedRoute>} />
        </Routes>
      </BrowserRouter>
      {/*
      <Footer />
       */}
    </div>
  );
}

function RootApp() {
  return (
    <TokenProvider>
      <App />
    </TokenProvider>
  );
}

export default RootApp;
